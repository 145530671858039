<template>
  <div>
    <div class="columns is-mobile is-multiline">
      <span class="column is-4">
        <b-field>
          <b-input
            v-model="search"
            placeholder="Search..."
            type="search"
            icon="search"
          ></b-input>
        </b-field>
      </span>
      <span class="column is-8">
        <b-field grouped position="is-right">
          <b-select v-model="perPage" @input="onPerPageChange">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </b-select>
        </b-field>
      </span>
    </div>

    <b-table
      :data="getCaseStudies && getCaseStudies.data ? getCaseStudies.data : []"
      :current-page.sync="currentPage"
      paginated
      backend-pagination
      :total="getCaseStudies && getCaseStudies.total ? getCaseStudies.total : 0"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >
      <b-table-column field="index" width="20" label="No" v-slot="props">
        {{ props.index + getCaseStudies.meta.from }}
      </b-table-column>
      <b-table-column
        field="page_title"
        label="Case Study"
        sortable
        v-slot="props"
      >
        <router-link :to="{ path: `/case-study/show/${props.row.id}` }">
          <b-button class="btn-edit" type="is-text">
            {{ props.row.pageTitle }}
          </b-button>
        </router-link>
      </b-table-column>
      <b-table-column
        field="created_at"
        label="Created At"
        sortable
        v-slot="props"
      >
        {{ props.row.createdAt }}
      </b-table-column>
      <b-table-column field="status" label="Status" sortable v-slot="props">
        <span class="tag is-warning">{{ props.row.status }}</span>
      </b-table-column>
      <b-table-column field="action" centered label="Action" v-slot="props">
        <b-button
          type="icon-button"
          icon-left="copy"
          title="Duplicate Case Study"
          @click="duplicatePopup(props.row.id, 'Inactive')"
        ></b-button>
        <b-button
          type="icon-button"
          icon-left="undo-alt"
          title="Active Case Study"
          @click="activePopup(props.row.id, props.index, 'Inactive')"
        ></b-button>
        <b-button
          type="icon-button"
          icon-left="trash"
          title="Delete Case Study"
          @click="deletePopup(props.row.id, props.index)"
        ></b-button>
      </b-table-column>
      <template slot="bottom-left">
        {{
          getCaseStudies && getCaseStudies.meta.from
            ? getCaseStudies.meta.from
            : 0
        }}
        -
        {{
          getCaseStudies && getCaseStudies.meta.to ? getCaseStudies.meta.to : 0
        }}
        from
        {{
          getCaseStudies && getCaseStudies.meta.total
            ? getCaseStudies.meta.total
            : 0
        }}
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="frown" size="is-large"></b-icon>
            </p>
            <p>Data not found.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'case-study-table-inactive',
  data() {
    const today = new Date()
    return {
      currentDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      ),
      isLoading: false,
      isLoadingTable: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      defaultSortOrder: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10,
      activeTab: 0,
      sortField: 'createdAt',
      sortOrder: 'DESC',
      page: 1,
      search: null,
      link: '',
      message: {
        text: '',
        type: '',
      },
      previewId: null,
      isModalPreviewActive: false,
      isModalLinkActive: false,
      status: 'Inactive',
    }
  },
  mounted() {
    this.fetchData(
      this.perPage,
      this.page,
      this.sortField,
      this.sortOrder,
      this.search,
      this.filter,
      this.status
    )
  },
  methods: {
    // For active popup
    activePopup(id, index, status) {
      this.$buefy.dialog.confirm({
        title: 'Active Case Study',
        message: `Are you sure want to active this case study?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, active it!',
        type: 'is-hcc',
        onConfirm: () => this.activeCaseStudy(id, index, status),
      })
    },

    // For active case study
    activeCaseStudy(id, index, status) {
      this.$store
        .dispatch('activeCaseStudy', { id, index, status })
        .then(() => {
          this.success('Successfully active case study')
        })
        .catch(() => {
          this.danger('Fail active case study')
        })
    },

    // For delete popup
    deletePopup(id, index) {
      this.$buefy.dialog.confirm({
        title: 'Delete Case Study',
        message: `Are you sure want to delete this case study?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, delete it!',
        type: 'is-hcc',
        onConfirm: () => this.deleteCaseStudy(id, index),
      })
    },

    // For delete case study
    deleteCaseStudy(id, index) {
      this.$store
        .dispatch('deleteCaseStudy', { id, index })
        .then(() => {
          this.success('Successfully delete case study')
        })
        .catch(() => {
          this.danger('Fail delete case study')
        })
    },

    // For duplicate popup
    duplicatePopup(id, status) {
      this.$buefy.dialog.confirm({
        title: 'Duplicate Case Study',
        message: `Are you sure want to duplicate this case study?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, duplicate it!',
        type: 'is-danger',
        onConfirm: () => this.duplicateCaseStudy(id, status),
      })
    },

    // For duplicate case study
    duplicateCaseStudy(id, status) {
      this.$store
        .dispatch('duplicateCaseStudy', { id, status })
        .then(() => {
          this.success('Successfully duplicate case study')
        })
        .catch(() => {
          this.danger('Fail duplicate case study')
        })
    },

    // For search case study
    searchCaseStudy: debounce(function(value) {
      if (value) {
        this.search = value
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search,
          this.filter,
          this.status
        )
      } else {
        this.search = null
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search,
          this.filter,
          this.status
        )
      }
    }, 500),

    // For generate link case study
    generateLink(id, code) {
      this.isModalLinkActive = true
      this.link =
        process.env.VUE_APP_BASE_URL + '/case-study/' + id + '/' + code
    },

    // For close modal link in index
    onClose() {
      this.isModalLinkActive = false
      this.link = ''
      this.message = {
        text: '',
        type: '',
      }
    },

    // For after success copy message link in index
    onSuccessCopyMessage() {
      this.message.text = 'Successfully copy link!'
      this.message.type = 'success'
    },

    // For after error copy message link in index
    onErrorCopyMessage() {
      this.message.text = 'Error copy link!'
      this.message.type = 'error'
    },

    // For fetch data from backend
    async fetchData(
      perPage,
      page,
      sortField,
      sortOrder,
      search,
      filter,
      status
    ) {
      this.tableLoading = true
      await this.$store.dispatch('getCaseStudies', {
        perPage,
        page,
        sortField,
        sortOrder,
        search,
        filter,
        status,
      })
      this.tableLoading = false
    },

    // For page change data
    onPageChange(page) {
      this.currentPage = page
      this.page = page
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.filter,
        this.status
      )
    },

    // For sort data
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.filter,
        this.status
      )
    },

    // For per page change
    onPerPageChange(value) {
      this.perPage = value
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.filter,
        this.status
      )
    },

    // For success alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
  watch: {
    search: function(val) {
      this.searchCaseStudy(val)
    },
  },
  computed: {
    ...mapGetters(['getCaseStudies']),
  },
}
</script>
