<template>
  <div>
    <div class="columns is-mobile is-multiline">
      <span class="column is-4">
        <b-field>
          <b-input
            v-model="search"
            placeholder="Search..."
            type="search"
            icon="search"
          ></b-input>
        </b-field>
      </span>
      <span class="column is-8">
        <b-field grouped position="is-right">
          <b-select v-model="perPage" @input="onPerPageChange">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </b-select>
        </b-field>
      </span>
    </div>

    <b-table
      :data="getAssessments && getAssessments.data ? getAssessments.data : []"
      :current-page.sync="currentPage"
      paginated
      backend-pagination
      :total="getAssessments && getAssessments.total ? getAssessments.total : 0"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >
      <b-table-column
        field="index"
        width="20"
        numeric
        label="No"
        v-slot="props"
      >
        {{ props.index + getAssessments.from }}
      </b-table-column>
      <b-table-column
        field="pageTitle"
        label="Assessment"
        sortable
        v-slot="props"
      >
        <router-link :to="{ path: `/assessment/show/${props.row.id}` }">
          <b-button class="btn-edit" type="is-text">
            {{ props.row.pageTitle }}
          </b-button>
        </router-link>
      </b-table-column>
      <b-table-column
        field="createdAt"
        label="Created At"
        sortable
        v-slot="props"
      >
        {{ props.row.createdAt }}
      </b-table-column>
      <b-table-column field="status" label="Status" sortable v-slot="props">
        <span class="tag is-success">{{ props.row.status }}</span>
      </b-table-column>
      <b-table-column field="action" centered label="Action" v-slot="props">
        <router-link
          :to="{ path: `/preview/assessment/${props.row.id}` }"
          target="_blank"
        >
          <b-button
            type="icon-button"
            icon-left="eye"
            title="Preview Assessment"
          ></b-button>
        </router-link>
        <b-button
          type="icon-button"
          icon-left="link"
          @click="generateLink(props.row.id, props.row.assessmentCode)"
          title="Generate Link Assessment"
        ></b-button>
        <b-button
          type="icon-button"
          icon-left="copy"
          title="Duplicate Assessment"
          @click="duplicatePopup(props.row.id, 'Active')"
        ></b-button>
        <b-button
          type="icon-button"
          icon-left="archive"
          title="Inactive Assessment"
          @click="inactivePopup(props.row.id)"
        ></b-button>
      </b-table-column>
      <template slot="bottom-left">
        {{ getAssessments && getAssessments.from ? getAssessments.from : 0 }}
        -
        {{ getAssessments && getAssessments.to ? getAssessments.to : 0 }}
        from
        {{ getAssessments && getAssessments.total ? getAssessments.total : 0 }}
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="frown" size="is-large"></b-icon>
            </p>
            <p>Data not found.</p>
          </div>
        </section>
      </template>
    </b-table>

    <b-modal
      :active.sync="isModalLinkActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card modal-copy-link" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Link For Answer</p>
          <button type="button" class="delete" @click="onClose" />
        </header>
        <section class="modal-card-body">
          <b-field label="Link for answer" :label-position="'on-border'">
            <b-input
              type="text"
              expanded
              class="url-link"
              :value="link"
              readonly
            ></b-input>
            <p class="control">
              <b-button
                icon-left="clipboard"
                v-clipboard:copy="link"
                v-clipboard:success="onSuccessCopyMessage"
                v-clipboard:error="onErrorCopyMessage"
              ></b-button>
            </p>
          </b-field>
          <span :class="message.type == 'success' ? 'success' : 'required'">
            {{ message.text }}
          </span>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="onClose">Close</button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  data() {
    const today = new Date()
    return {
      currentDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      ),
      isLoading: false,
      isLoadingTable: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      defaultSortOrder: 'desc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10,
      activeTab: 0,
      sortField: 'createdAt',
      sortOrder: 'DESC',
      page: 1,
      search: null,
      status: 'Active',
      link: '',
      message: {
        text: '',
        type: '',
      },
      isModalPreviewActive: false,
      isModalLinkActive: false,
    }
  },
  mounted() {
    this.fetchData(
      this.perPage,
      this.currentPage,
      this.sortField,
      this.sortOrder,
      this.search,
      this.status
    )
  },
  methods: {
    // For search assessment
    searchAssessment: debounce(function(value) {
      if (value) {
        this.search = value
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search,
          this.status
        )
      } else {
        this.search = null
        this.fetchData(
          this.perPage,
          this.page,
          this.sortField,
          this.sortOrder,
          this.search,
          this.status
        )
      }
    }, 500),

    previewAssessment(id) {
      this.isModalPreviewActive = true
      this.previewId = id
    },

    // For duplicate popup
    duplicatePopup(id, status) {
      this.$buefy.dialog.confirm({
        title: 'Duplicate Assessment',
        message: `Are you sure want to duplicate this assessment?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, duplicate it!',
        type: 'is-hcc',
        onConfirm: () => this.duplicateAssessment(id, status),
      })
    },

    // For duplicate survey
    duplicateAssessment(id, status) {
      this.$store
        .dispatch('duplicateAssessment', { id, status })
        .then(() => {
          this.success('Successfully duplicate a assessment')
        })
        .catch(() => {
          this.danger('Fail duplicate a assessment')
        })
    },

    // For inactive popup
    inactivePopup(id) {
      this.$buefy.dialog.confirm({
        title: 'Inactive Assessment',
        message: `Are you sure want to inactive this assessment?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, inactive it!',
        type: 'is-danger',
        onConfirm: () => this.inactiveAssessment(id),
      })
    },

    // For inactive assessment
    inactiveAssessment(id) {
      this.$store
        .dispatch('archiveAssessment', { id, status })
        .then(() => {
          this.success('Successfully inactive a assessment')
        })
        .catch(() => {
          this.danger('Fail inactive a assessment')
        })
    },

    // For fetching data
    async fetchData(perPage, page, sortField, sortOrder, search, status) {
      this.isLoadingTable = false
      await this.$store.dispatch('getAssessments', {
        perPage,
        page,
        sortField,
        sortOrder,
        search,
        status,
      })
      this.isLoadingTable = true
    },

    // For Page Change
    onPageChange(page) {
      this.currentPage = page
      this.page = page
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status
      )
    },

    // For Sorting Data
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status
      )
    },

    // For per page change
    onPerPageChange(value) {
      this.perPage = value
      this.fetchData(
        this.perPage,
        this.page,
        this.sortField,
        this.sortOrder,
        this.search,
        this.status
      )
    },

    // For generate link in index
    generateLink(id, code) {
      this.isModalLinkActive = true
      this.link =
        process.env.VUE_APP_BASE_URL + '/assessment/' + id + '/' + code
    },

    // For close modal link in index
    onClose() {
      this.isModalLinkActive = false
      this.link = ''
      this.message = {
        text: '',
        type: '',
      }
    },

    // For after success copy message link in index
    onSuccessCopyMessage() {
      this.message.text = 'Successfully copy link!'
      this.message.type = 'success'
    },

    // For after error copy message link in index
    onErrorCopyMessage() {
      this.message.text = 'Error copy link!'
      this.message.type = 'error'
    },

    // For success alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
  watch: {
    search: function(val) {
      this.searchAssessment(val)
    },
  },
  computed: {
    ...mapGetters(['getAssessments']),
  },
}
</script>
<style scoped>
.modal-copy-link {
  width: 500px !important;
}
</style>
