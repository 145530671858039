<template>
  <div class="main index" v-if="!isLoading">
    <div class="columns is-mobile is-multiline">
      <div class="column is-12">
        <h1 class="is-size-3"><b>Assessment Setup</b></h1>
      </div>
      <div class="column is-10">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li>
              <h3 class="is-size-6">Assessment</h3>
            </li>
            <li>
              <h3 class="is-size-6 breadcrumb-item">Assessment Setup</h3>
            </li>
          </ul>
        </nav>
      </div>
      <div class="column is-2">
        <router-link :to="{ name: `createAssessment` }">
          <b-field grouped position="is-right">
            <b-button type="is-hcc" title="Create Assessment">
              Create New
            </b-button>
          </b-field>
        </router-link>
      </div>
    </div>
    <!-- <div class="columns is-mobile is-multiline">
      <span class="column is-4">
        <b-field>
          <b-input
            v-model="search"
            placeholder="Search..."
            type="search"
            icon="search"
          ></b-input>
        </b-field>
      </span>
      <span class="column is-8">
        <b-field grouped position="is-right">
          <b-select v-model="perPage">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </b-select>
        </b-field>
      </span>
    </div> -->
    <div class="columns is-mobile">
      <div class="column is-12">
        <div class="card card-table">
          <b-tabs v-model="activeTab">
            <b-tab-item>
              <template slot="header">
                <span class="tab">Active</span>
              </template>
              <AssessmentTableActive v-if="activeTab == 0" />
            </b-tab-item>
            <b-tab-item>
              <template slot="header">
                <span class="tab">Inactive</span>
              </template>
              <AssessmentTableInactive v-if="activeTab == 1" />
            </b-tab-item>
            <!-- <b-tab-item>
              <template slot="header">
                <span class="tab">Archive</span>
              </template>
              <AssessmentTableArchive v-if="activeTab == 2" />
            </b-tab-item> -->
            <b-tab-item>
              <template slot="header">
                <span class="tab">All Assessment</span>
              </template>
              <AssessmentTableAllType v-if="activeTab == 2" />
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssessmentTableActive from './assessment-table/AssessmentTableActive'
import AssessmentTableInactive from './assessment-table/AssessmentTableInactive'
// import AssessmentTableArchive from "./assessment-table/AssessmentTableArchive";
import AssessmentTableAllType from './assessment-table/AssessmentTableAllType'

export default {
  components: {
    AssessmentTableActive,
    AssessmentTableInactive,
    // AssessmentTableArchive,
    AssessmentTableAllType,
  },
  data() {
    return {
      activeTab: 0,
      isLoading: false,
    }
  },
}
</script>
<style>
.modal-card-body {
  padding: 20px !important;
}
.modal-card-head,
.modal-card-foot {
  padding: 20px !important;
}
</style>
